<template>
  <div class="search" v-if="hasPerm('activities.view_coreactivity')">
    <b-form @submit="onSearch" id="search-activity-form">
      <b-form-group
        id="search-group"
        label-for="search-activity"
        description="Rechercher une activité à partir de son nom"
      >
        <b-form-row>
          <b-col cols="12">
            <b-input-group prepend="Recherche" class="mt-3 input-group-primary">
              <b-form-input
                id="search-activity"
                type="search"
                placeholder="Entrez le nom d'une activité"
                v-model="searchText"
                autocomplete="off"
                :class="{ filled: searchText }"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button type="submit" variant="primary">
                  <i class="fa fa-search"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-form-row>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeActivity } from '@/types/activities'
import { BackendApi } from '@/utils/http'

export default {
  name: 'SearchActivity',
  mixins: [BackendMixin],
  props: {
    schoolYear: Object,
    initial: String,
    category: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      searchText: '',
    }
  },
  watch: {
    schoolYear: function() {
      this.searchActivities(this.searchText)
    },
    initial: function() {
      this.searchText = this.initial
    },
  },
  mounted() {
    this.searchText = this.initial
    this.searchActivities(this.searchText, false)
  },
  computed: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    onSearch(evt) {
      evt.preventDefault()
      this.searchActivities(this.searchText, true)
    },
    async searchActivities(text, event) {
      if (text) {
        const loadingName = 'searchActivities'
        this.startLoading(loadingName)
        try {
          let url = '/api/activities/activities/search/?text=' + text + '&category=' + this.category
          if (this.schoolYear && this.schoolYear.id) {
            url += '&school_year=' + this.schoolYear.id
          }
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          let activities = await resp.data.map(elt => makeActivity(elt))
          this.$emit('search', { text: this.searchText, activities: activities, })
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(loadingName)
      } else {
        if (event) {
          this.$emit('search', { text: '', activities: [], })
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
