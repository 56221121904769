<template>
  <span class="disable-activity-button" v-if="canDisable">
    <a href @click.prevent="showModal" class="btn btn-sm" :class="buttonStyle" :title="buttonLabel">
      <i :class="buttonIcon"></i>
    </a>
    <b-modal
      dialog-class="modal-md"
      :id="getId()"
      cancel-title="Annuler"
      @ok.prevent="onSave"
      ok-variant="primary"
      :ok-title="actionText"
    >
      <template v-slot:modal-title>
        <b><i :class="buttonIcon"></i> {{ actionText }}
          <span v-if="youthHome">un accueil de loisirs</span>
          <span v-else>une activité</span>
        </b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div class="confirm-text" v-if="!activity.disabled">
        <b>Souhaitez-vous suspendre l'activité {{ activity.name }}?</b><br />
        Les inscriptions seront impossible.
      </div>
      <div class="confirm-text" v-if="activity.disabled">
        <b>Souhaitez-vous réactiver l'activité {{ activity.name }}?</b><br />
        Les inscriptions seront de nouveau possible.
      </div>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeActivity } from '@/types/activities'
import { BackendApi } from '@/utils/http'
import { getRandomId } from '@/utils/random'
import store from '@/store'

export default {
  name: 'disable-activity-button',
  mixins: [BackendMixin],
  props: {
    activity: Object,
    youthHome: Number,
  },
  components: {
  },
  data() {
    return {
      randomId: getRandomId(),
      errorText: '',
    }
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    actionText() {
      if (this.activity.disabled) {
        return 'Réactiver'
      } else {
        return 'Suspendre'
      }
    },
    buttonStyle() {
      if (this.activity.disabled) {
        return 'btn-danger'
      } else {
        return 'btn-secondary'
      }
    },
    buttonLabel() {
      if (this.activity.disabled) {
        return 'Suspendue'
      } else {
        return 'Suspendre'
      }
    },
    buttonIcon() {
      if (this.activity.disabled) {
        return 'fas fa-lock'
      } else {
        return 'fas fa-lock-open'
      }
    },
    canDisable() {
      if (this.youthHome) {
        return this.hasPerm('youth.change_youthhomeactivity')
      } else {
        return this.hasPerm('activities.change_coreactivity')
      }
    },
  },
  watch: {
    activity: function(newValue, oldValue) {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-disable-activity-button' + this.randomId
    },
    async onSave() {
      this.errorText = ''
      try {
        let url = '/api/activities/disable-activity/' + this.activity.id + '/'
        if (this.youthHome) {
          url = '/api/youth/disable-activity/' + this.activity.id + '/'
        }
        const backendApi = new BackendApi('post', url)
        let data = {
          disabled: !this.activity.disabled,
        }
        const resp = await backendApi.callApi(data)
        const activity = makeActivity(resp.data)
        this.$bvModal.hide(this.getId())
        if (this.youthHome) {
          if (activity.disabled) {
            await this.addSuccess(this.youthHomeLabel + ' suspendue')
          } else {
            await this.addSuccess(this.youthHomeLabel + ' réactivée')
          }
        } else {
          if (activity.disabled) {
            await this.addSuccess('L\'activité a été suspendue')
          } else {
            await this.addSuccess('L\'activité a été réactivée')
          }
        }
        this.$emit('updated', activity)
      } catch (err) {
        this.errorText = this.getErrorText(err)
        this.addError(this.getErrorText(err))
      }
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
  .confirm-text {
  }
</style>
