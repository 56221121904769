<template>
  <span v-if="activity && activity.id">
    <span class="activity-inscriptions-count-sign" :style="style">
      <div class="main-counter">
        <counter-label :counter="activity.seatsCount" label="place" v-if="activity.isEvent()">
        </counter-label>
        <counter-label :counter="activity.inscriptionsCount" label="inscrit" v-else>
        </counter-label>
      </div>
      <div v-b-tooltip="'dont à l\'essai'" class="sub-counter">
        {{ activity.tryingCount }}
      </div>
      <div v-b-tooltip="'en attente'" class="sub-counter">
        {{ waitingCount }}
      </div>
      <div v-b-tooltip="'minimum de participants'" class="sub-counter">
        {{ activity.minimumParticipants | defaultValue('-') }}
      </div>
      <div v-b-tooltip="'maximum de participants'" class="sub-counter">
        {{ activity.maximumParticipants | defaultValue('-') }}
      </div>
    </span>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { getRandomId } from '@/utils/random'
import CounterLabel from '@/components/Controls/CounterLabel'

export default {
  name: 'ActivityInscriptionsCountBadge',
  components: {
    CounterLabel,
  },
  props: {
    activity: Object,
    block: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      randomId: getRandomId(),
    }
  },
  watch: {
    activity: function() {
      this.checkMaxLimit()
    },
    block: function() {},
  },
  computed: {
    waitingCount() {
      if (this.activity.isEvent()) {
        return this.activity.waitingSeatsCount
      } else {
        return this.activity.waitingCount
      }
    },
    currentCount() {
      return this.activity.isEvent() ? this.activity.seatsCount : this.activity.inscriptionsCount
    },
    style() {
      let backgroundColor = '#fff'
      if (
        (!this.activity.minimumParticipants) && (!this.activity.maximumParticipants)
      ) {
        backgroundColor = '#ccc' // pas de règle
      } else {
        if (
          this.activity.minimumParticipants && this.currentCount < this.activity.minimumParticipants
        ) {
          backgroundColor = '#7ec7f1' // Nombre min pas atteint
        } else if (
          this.activity.maximumParticipants && this.currentCount >= this.activity.maximumParticipants
        ) {
          backgroundColor = '#f14d4d' // Nombre max atteint
        } else {
          backgroundColor = '#67e86a' // OK
        }
      }
      const style = {
        backgroundColor: backgroundColor,
      }
      if (this.block) {
        style.display = 'block'
      }
      return style
    },
  },
  methods: {
    checkMaxLimit() {
      if (this.activity && this.activity.id) {
        const maxLimit = (
          this.activity.maximumParticipants && (this.currentCount >= this.activity.maximumParticipants)
        )
        this.$emit('max-limit', { maxLimit: maxLimit, })
      }
    },
  },
  mounted() {
    this.checkMaxLimit()
  },
}
</script>
<style lang="less">
.activity-inscriptions-count-sign {
  display: inline-block;
  padding: 5px 10px;
  color: #000;
  border-radius: 4px;

  .main-counter {
    display: inline-block;
  }
  .sub-counter {
    display: inline-block;
    padding: 2px;
    text-align: center;
    border-radius: 4px;
    border: solid 1px #444;
    font-size: 11px;
    margin: 0 4px;
    min-width: 24px;
  }
}
</style>
