<template>
  <span class="multi-activities-select">
    <b-modal
      dialog-class="modal-lg"
      id="bv-multi-activities-select-modal"
      @ok.prevent="onOK()"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Voir"
      :ok-disabled="formInvalid"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-chess-knight"></i> {{ title }}</b>
      </template>
      <b-row>
        <b-col>
          <check-box-select
            id="activities"
            :choices="activities"
            :initial-value="activities"
            @changed="activitiesChanged($event)"
          ></check-box-select>
        </b-col>
      </b-row>
      <div v-if="askTitle" class="ask-options">
        <b-row>
          <b-col>
            <b-checkbox v-model="groupAll" id="groupAll">
              Grouper sur un seul listing
            </b-checkbox>
          </b-col>
        </b-row>
        <b-row v-if="groupAll">
          <b-col>
            <b-form-group label-for="pageTitle" label="Titre" description="Définir un titre spécifique">
              <b-input type="text" id="pageTitle" v-model="pageTitle"></b-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </span>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import router from '@/router'

export default {
  name: 'MultiActivitiesSelectModal',
  components: { CheckBoxSelect, },
  mixins: [BackendMixin],
  props: {
    activities: Array,
    title: {
      type: String,
      default: 'Sélectionner les activités',
    },
    askTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedActivities: [],
      pageTitle: '',
      groupAll: false,
    }
  },
  mounted() {
    this.selectedActivities = this.activities
    this.groupAll = false
  },
  computed: {
    formInvalid() {
      return (this.selectedActivities.length === 0)
    },
  },
  watch: {
    activities: function() {
      this.selectedActivities = this.activities
    },
    selectedActivities: function() {
      if (this.selectedActivities.length) {
        this.pageTitle = this.selectedActivities[0].name
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    activitiesChanged(event) {
      this.selectedActivities = event.choices
    },
    async onOK() {
      let globalTitle = ''
      if (this.groupAll) {
        if (this.pageTitle) {
          globalTitle = this.pageTitle
        } else {
          globalTitle = 'Inscrits'
        }
      }
      this.$emit(
        'selected',
        {
          activities: this.selectedActivities,
          globalTitle: globalTitle,
        }
      )
    },
  },
}
</script>

<style scoped>
.ask-options {
  margin-right: 20px;
  padding: 10px;
  background: #eee;
  border-radius: 4px;
}
</style>
